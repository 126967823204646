import React, { useState, Dispatch, SetStateAction, useEffect } from "react";

import { Container, Button, FlexGrid, P } from "@util/standard";
import { useStore } from "@state/store";
import AccountTemplate from "@components/account/accountTemplate";
import ProductPreview from "@shared/productPreview";
import { Button as CustomButton } from "@global";
import { useAllProductDeclaredWeightQuery } from "@api";
import { Maybe, SanityProduct } from "@graphql-types";

type IdProps = {
  sanity: string;
  gatsby: string;
};

const getProduct = async (
  ids: IdProps[],
  setSanityProduct: Dispatch<SetStateAction<Maybe<SanityProduct[]>>>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const data = {
    id: ids,
  };
  setLoading(true);
  const res = await fetch(`/api/sanityProduct`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  try {
    const response = await res.json();
    setSanityProduct(response.product);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    throw err;
  }
};

export default function Account() {
  const { shopify, savedProducts } = useStore();
  const [shownNumber, setShownNumber] = useState(15);
  const [sanityProduct, setSanityProduct] =
    useState<Maybe<SanityProduct[]>>(null);
  const [loading, setLoading] = useState(false);
  const allProductsID = useAllProductDeclaredWeightQuery();

  useEffect(() => {
    if (savedProducts.length > 0) {
      const ids = savedProducts.map(savedId => {
        const id =
          allProductsID?.nodes?.find(product => {
            return product.id === savedId;
          })?._id ?? "";
        return {
          sanity: id,
          gatsby: savedId,
        };
      });
      getProduct(ids, setSanityProduct, setLoading);
    }
  }, [savedProducts]);

  if (
    !savedProducts ||
    savedProducts.length < 1 ||
    sanityProduct == null ||
    sanityProduct.length < 0
  ) {
    return (
      <AccountTemplate shopify={shopify} handle="products">
        <Container
          flexDirection="column"
          alignItems="center"
          width="100%"
          padding="50px 0"
        >
          <P>{loading ? `Loading...` : `You have no Saved Products`}</P>
          <CustomButton
            theme="transparent"
            linkTo="/shop-all"
            text="VIEW PRODUCTS"
          />
        </Container>
      </AccountTemplate>
    );
  }

  return (
    <AccountTemplate shopify={shopify} handle="products">
      <FlexGrid
        gridMargin="25px"
        laptopGridMargin="20px"
        tabletGridMargin="12px"
      >
        {sanityProduct.slice(0, shownNumber).map(product => {
          if (!product) return;
          return (
            <Container
              key={product.id}
              margin="0 0 100px"
              tabletMargin="0 0 55px"
            >
              <ProductPreview noMargin={true} product={product} isFav />
            </Container>
          );
        })}
        {shownNumber < sanityProduct.length && (
          <Container width="100%" margin="0 0 100px" tabletMargin="0 0 55px">
            <Button
              onClick={() => {
                setShownNumber(pre => pre + 15);
              }}
            >
              Load More
            </Button>
          </Container>
        )}
      </FlexGrid>
    </AccountTemplate>
  );
}
