import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import fetch from "unfetch";

import { useStore } from "@state/store";
import {
  Container,
  StyledOverlay,
  FlexGrid,
  HeroH1,
  HeroH3,
} from "@util/standard";
import { assets, TABLET_BREAKPOINT } from "@util/constants";
import { Shopify } from "@state/types";
import AccountNavigation from "@components/account/accountNavigation";
import { pageWidthMargin, updateCustomer, customerLogout } from "@util/helper";
import { AccountHandles } from "@util/types";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  shopify: Shopify;
  children: ReactNode;
  handle: AccountHandles;
}

const HeroWrapper = styled(Container)`
  position: relative;
  width: 100%;
  background-size: cover;
  background-image: url(${assets.accountHero});
  min-height: 550px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    min-height: 220px;
  }
`;

const logout = () => {
  const shopify = useStore.getState().shopify;
  fetch(`/api/logout`, {
    method: "POST",
    body: JSON.stringify({
      accessToken: shopify?.customer?.token,
    }),
  }).then(() => {
    customerLogout({ shopify });

    setTimeout(() => {
      navigate("/");
    }, 100);
  });
};

const getCustomer = async () => {
  const shopify = useStore.getState().shopify;
  if (!shopify.customer?.token) return;
  const res = await fetch(`/api/customer`, {
    method: "POST",
    body: JSON.stringify({
      accessToken: shopify.customer?.token,
    }),
  });
  try {
    const response = await res.json();
    updateCustomer(response, shopify);
  } catch (err) {
    throw err;
  }
};

const AccountTemplate = ({ shopify, children, handle }: Props) => {
  const margin = pageWidthMargin();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const isSmallScreen = isMobileWidth === true || isTabletWidth === true;

  useEffect(() => {
    const onLoad = async () => {
      await getCustomer();
      if (!shopify.customer?.email) {
        navigate("/");
      }
    };
    onLoad();
  }, []);

  return (
    <>
      <SEO />
      <Layout>
        <Container flexDirection="column">
          <Container>
            <HeroWrapper>
              <Container
                width="100vw"
                height="550px"
                margin="0px"
                display="flex"
                flexDirection="row"
                mobileDimensions={{ height: "220px" }}
              >
                <Container
                  width="340px"
                  margin={`auto auto 75px ${margin}`}
                  display="flex"
                  flexDirection="column"
                  zIndex={20}
                  mobileWidth={`calc(100% - ${margin} - ${margin})`}
                  mobileMargin="auto auto 35px"
                >
                  <HeroH3 homeHero={false}>YOUR ACCOUNT</HeroH3>
                  {shopify?.customer.firstName && (
                    <HeroH1
                      homeHero={false}
                    >{`Welcome ${shopify?.customer.firstName}`}</HeroH1>
                  )}
                </Container>
                <StyledOverlay height="100%" />
              </Container>
            </HeroWrapper>
          </Container>

          <Container
            height="auto"
            width="100%"
            padding={`85px ${margin} 100px`}
            mobilePadding={`35px ${margin} 55px`}
            display="flex"
            flexDirection="row"
          >
            <FlexGrid
              gridMargin="35px"
              laptopGridMargin="20px"
              tabletGridMargin="12px"
            >
              <Container
                flexDirection="column"
                width={isSmallScreen ? "100%" : "20%"}
              >
                <AccountNavigation logoutFunc={logout} handle={handle} />
              </Container>
              <Container width={isSmallScreen ? "100%" : "80%"}>
                {children}
              </Container>
            </FlexGrid>
          </Container>
        </Container>
      </Layout>
    </>
  );
};

export default AccountTemplate;
