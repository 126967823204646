import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Container } from "@util/standard";
import {
  colors,
  colorsRGB,
  DEFAULT_FONT,
  assets,
} from "@util/constants";
import { AccountHandles } from "@util/types";
import Accordion from "react-collapsy";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  logoutFunc: () => void;
  handle: AccountHandles;
}

const StyledTextLink = styled(Link) <{
  bold: "true" | "false";
}>`
  ${({ bold }) => bold === "true" && `
  font-weight: bold;
  pointer-events: none;
  `}
`;

const StyledContainer = styled(Container)`
  a{
    padding: 6px 0;
    color: ${colorsRGB.merlin(0.8)};
  }
`;
const AccordionContainer = styled.div`
  .Accordion{
    border-radius: 5px; 
    border: 1px solid ${colorsRGB.merlin(0.6)};
  }
  .Accordion__header{
    background: transparent;
    color: ${colors.merlin}; 
    font-family: ${DEFAULT_FONT};
    &:after{
      width: 10px;
      height: 5px;
      right: 18px;
      background: url(${assets.arrowDownDark});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .Accordion__body{
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 0;
    border-top: 1px solid ${colorsRGB.merlin(0.6)};
    &.Accordion__body--collapsed{
      padding: 0 70px;
    }
    a{
      padding: 6px 15px;
    }
  }
`;

const navTitle = {
  recipes: "Saved Recipe",
  products: "Saved Products",
  blogs: "Saved Blogs",
  orders: "Order History",
  preferences: "Preferences",
}

const AccountNavigation = ({ logoutFunc, handle }: Props) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const isSmallScreen = isMobileWidth === true || isTabletWidth === true;

  if (isSmallScreen) {
    return (
      <AccordionContainer>
        <Accordion
          title={navTitle[handle]}
          isOpen={false}
        >
          <StyledTextLink bold={handle === "recipes" ? "true" : "false"} to="/account">{navTitle.recipes}</StyledTextLink>
          <StyledTextLink bold={handle === "products" ? "true" : "false"} to="/account/products">{navTitle.products}</StyledTextLink>
          <StyledTextLink bold={handle === "blogs" ? "true" : "false"} to="/account/blogs">{navTitle.blogs}</StyledTextLink>
          <StyledTextLink bold={handle === "orders" ? "true" : "false"} to="/account/orders">{navTitle.orders}</StyledTextLink>
          <StyledTextLink bold={handle === "preferences" ? "true" : "false"} to="/account/preferences">{navTitle.preferences}</StyledTextLink>
          <a onClick={logoutFunc}>Logout</a>
        </Accordion>
      </AccordionContainer>
    );
  }

  return (
    <StyledContainer flexDirection="column">
      <StyledTextLink bold={handle === "recipes" ? "true" : "false"} to="/account">Saved Recipe</StyledTextLink>
      <StyledTextLink bold={handle === "products" ? "true" : "false"} to="/account/products">Saved Products</StyledTextLink>
      <StyledTextLink bold={handle === "blogs" ? "true" : "false"} to="/account/blogs">Saved Blogs</StyledTextLink>
      <StyledTextLink bold={handle === "orders" ? "true" : "false"} to="/account/orders">Order History</StyledTextLink>
      <StyledTextLink bold={handle === "preferences" ? "true" : "false"} to="/account/preferences">Preferences</StyledTextLink>
      <a onClick={logoutFunc}>Logout</a>
    </StyledContainer>
  );
};

export default AccountNavigation;